import React, {createContext, ReactNode, useContext, useMemo} from 'react'
import {useMutation} from 'react-query'

import {OnViewContextType} from 'app/types/providers.type'
import {useApp} from 'app/providers/app.provider'
import {useOnLogin} from 'app/providers/onlogin.provider'
import {IMember} from 'app/models/members.model'
import {MemberListQuery} from 'app/api/members.api'
import {IDocument, IDocumentDetail} from 'app/models/documents.model'
import {
    RulesDownloadQuery,
    DocumentDetailQuery,
    DocumentDownloadQuery,
    DocumentListQuery,
    TermsOfSalesDownloadQuery
} from 'app/api/documents.api'
import {AccountingDocumentIndividualListQuery, AccountingDocumentListQuery} from 'app/api/accountingDocuments.api'
import {IAccountingDocument} from 'app/models/accountingDocuments.model'
import {
  IContractServiceFromApi,
  ICustomerReservation,
  INomadeCounter,
  INomadeCounterForPricing,
  IReservation,
  IReservationForPricing,
  IScheduleDesktop,
  IService
} from 'app/models/booking.model'
import Session from 'app/libraries/session.lib'
import {
  CommonMeetingServiceListQuery,
  CreateCustomerReservationQuery,
  CustomerReservationListQuery,
  DeleteContractServiceQuery,
  MeetingServiceListQuery,
  NomadeCounterQuery,
  PricingCustomerReservationQuery,
  ScheduleDesktopListQuery
} from 'app/api/booking.api'
import {IFile} from 'app/models/files.model'
import {ClientRequestCreateQuery, ClientRequestListQuery, ClientRequestCategoriesQuery} from 'app/api/helpdesk.api'
import {IRequest} from 'app/models/requests.model'
import {IQuotation} from 'app/models/quotations.models'
import {QuotationGetQuery} from 'app/api/quotations.api'
import {IPayment, IPaymentIntent, IPaymentMethod, IRedirectFlowGc} from 'app/models/payments.models'
import {
  CompletePaymentCbQuery,
  CreatePaymentIntentQuery,
  GcMandateConnectedLinkGetQuery,
  GcMandateLinkGetQuery,
  PaymentGetQuery,
  PaymentMethodListQuery,
} from 'app/api/payments.api'
import {
  ContractPdfQuery,
  ContractQuery,
  PaymentMethodListConnectedQuery,
  RequestSmsQuery,
  SendSmsQuery
} from 'app/api/contracts.api'
import {IContract, IContractDetail, IPdf} from 'app/models/contracts.model'
import {TokenCheckQuery} from '../api/accounts.api'
import {ICredit} from 'app/models/credits.models'
import {CreditGetQuery} from '../api/credits.api'
import {IErrorResponse} from '../models/api.model'
import {HelpdeskCategories} from "../models/helpdesk.models";

interface IProps {
  children: ReactNode
}

const OnViewContext = createContext<OnViewContextType>({
  /* Accounting documents */
  listAccountingDocumentUseMutation: undefined,
  listAccountingDocumentIndividualUseMutation: undefined,

  /* Booking */
  listCustomerReservationUseMutation: undefined,
  listMeetingServiceUseMutation: undefined,
  listCommonMeetingServiceUseMutation: undefined,
  customerReservationCreateUseMutation: undefined,
  listScheduleDesktopUseMutation: undefined,
  pricingCustomerReservationUseMutation: undefined,
  deleteContractServiceUseMutation: undefined,
  nomadeCounterUseMutation: undefined,

  /* Contracts */
  requestSmsPendingContractUseMutation: undefined,
  sendSmsPendingContractUseMutation: undefined,
  contractDetailsUseMutation: undefined,
  contractPdfUseMutation: undefined,

  /* Documents */
  listDocumentUseMutation: undefined,
  documentDetailsUseMutation: undefined,
  documentDownloadUseMutation: undefined,

  /* Enterprises */
  gcMandateConnectedLinkGetUseMutation: undefined,
  paymentMethodListConnectedUseMutation: undefined,

  /* Helpdesk */
  listClientRequestUseMutation: undefined,
  clientRequestUseMutation: undefined,
  clientRequestCategoriesUseMutation: undefined,

  /* Members */
  listMemberUseMutation: undefined,

  /* Payments */
  paymentMethodListUseMutation: undefined,
  paymentGetUseMutation: undefined,
  gcMandateLinkGetUseMutation: undefined,
  createPaymentIntentUseMutation: undefined,
  completePaymentGcUseMutation: undefined,

  /* Credits */
  creditGetUseMutation: undefined,

  /* Quotations */
  quotationGetUseMutation: undefined,

  /* Others */
  rulesDownloadUseMutation: undefined,
  termsOfSalesDownloadUseMutation: undefined,
  tokenCheckUseMutation: undefined
})

const OnViewProvider = ({children}: IProps): JSX.Element => {
  const {throwAppException} = useApp()
  const {currentEnterprise} = useOnLogin()

  /* Accounting documents */
  const listAccountingDocumentUseMutation = useMutation<IAccountingDocument[], unknown, number>(
    'accountingDocumentList',
    async (enterpriseId: number) => await AccountingDocumentListQuery(enterpriseId),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const listAccountingDocumentIndividualUseMutation = useMutation<
    IAccountingDocument[],
    unknown,
    void,
    unknown
  >(
    'accountingDocumentIndividualList',
    async () => await AccountingDocumentIndividualListQuery(Session.getIndividualId()),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  /* Booking */
  const listCustomerReservationUseMutation = useMutation<ICustomerReservation[], unknown, number>(
    'customerReservationList',
    async (enterpriseId: number) =>
      await CustomerReservationListQuery(enterpriseId, Session.getIndividualId()),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const listMeetingServiceUseMutation = useMutation<IService[], unknown, number>(
    'meetingServiceList',
    async (centerId: number) => await MeetingServiceListQuery(centerId),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const listCommonMeetingServiceUseMutation = useMutation<IService[], unknown, unknown>(
    'commonMeetingServiceList',
    async () => await CommonMeetingServiceListQuery(),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const customerReservationCreateUseMutation = useMutation<
    IContractServiceFromApi,
    IErrorResponse,
    IReservationForPricing
  >(
    'customerReservationCreate',
    async (body: IReservationForPricing) =>
      await CreateCustomerReservationQuery(currentEnterprise, body),
    {
      onError: (err) => {throw err}
    }
  )

  const listScheduleDesktopUseMutation = useMutation<
    IScheduleDesktop[],
    unknown,
    { service: number; date: string; enterpriseId: number }
  >(
    'scheduleDesktopList',
    async ({service, date, enterpriseId}) =>
      await ScheduleDesktopListQuery(service, date, enterpriseId),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const pricingCustomerReservationUseMutation = useMutation<
    IReservation,
    IErrorResponse,
    { enterpriseId: number; body: IReservationForPricing }
  >(
    'pricingCustomerReservation',
    async ({enterpriseId, body}) => await PricingCustomerReservationQuery(enterpriseId, body),
    {
      onError: (err) => {throw err}
    }
  )

  const deleteContractServiceUseMutation = useMutation<
    unknown,
    unknown,
    { enterpriseId: number; id: number }
  >(
    'deleteContractService',
    async ({enterpriseId, id}) => await DeleteContractServiceQuery(enterpriseId, id),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const nomadeCounterUseMutation = useMutation<
    INomadeCounter,
    unknown,
    { enterpriseId: number; body: INomadeCounterForPricing }
  >(
    'nomadeCounter',
    async ({enterpriseId, body}) => await NomadeCounterQuery(enterpriseId, body),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  /* Contracts */
  const requestSmsPendingContractUseMutation = useMutation<
    boolean,
    unknown,
    { enterpriseId: number; id: number }
  >(
    'requestSmsPendingContract',
    async ({enterpriseId, id}) => await RequestSmsQuery(enterpriseId, id),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const sendSmsPendingContractUseMutation = useMutation<
    IContract,
    unknown,
    { enterpriseId: number; id: number; code: string }
  >(
    'sendSmsPendingContract',
    async ({enterpriseId, id, code}) => await SendSmsQuery(enterpriseId, id, code),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const contractDetailsUseMutation = useMutation<
    IContractDetail,
    unknown,
    { enterpriseId: number; id: number }
  >('contractDetails', async ({enterpriseId, id}) => await ContractQuery(enterpriseId, id), {
    onError: async (err: unknown) => throwAppException(Number(err))
  })

  const contractPdfUseMutation = useMutation<IPdf, unknown, { enterpriseId: number; id: number }>(
    'contractPdf',
    async ({enterpriseId, id}) => await ContractPdfQuery(enterpriseId, id),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  /* Documents */
  const listDocumentUseMutation = useMutation<IDocument[], unknown, number>(
    ['documentList', currentEnterprise],
    async (enterpriseId: number) => await DocumentListQuery(enterpriseId),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const documentDetailsUseMutation = useMutation<
    IDocumentDetail,
    unknown,
    { enterpriseId: number; id: number }
  >(
    'documentDetails',
    async ({enterpriseId, id}) => await DocumentDetailQuery(enterpriseId, id),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const documentDownloadUseMutation = useMutation<
    IFile,
    unknown,
    { enterpriseId: number; id: number }
  >(
    'documentDownload',
    async ({enterpriseId, id}) => await DocumentDownloadQuery(enterpriseId, id),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  /* Enterprises */
  const gcMandateConnectedLinkGetUseMutation = useMutation<IRedirectFlowGc, unknown, {
    enterpriseId: number,
    centerId: number
  }>(
    'gcMandateConnectedLinkGet',
    async ({enterpriseId, centerId}) => await GcMandateConnectedLinkGetQuery(enterpriseId, centerId),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const paymentMethodListConnectedUseMutation = useMutation<
    IPaymentMethod[],
    unknown,
    { enterpriseId: number; centerId: number }
  >('paymentMethodListConnected', async ({
                                           enterpriseId,
                                           centerId
                                         }) => await PaymentMethodListConnectedQuery(enterpriseId, centerId), {
    onError: async (err: unknown) => throwAppException(Number(err))
  })

  /* Helpdesk */
  const listClientRequestUseMutation = useMutation<IRequest[], unknown, number>(
    'clientRequestList',
    async (enterpriseId: number) =>
      await ClientRequestListQuery(enterpriseId, Session.getIndividualId()),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const clientRequestUseMutation = useMutation<
    boolean,
    unknown,
    { enterpriseId: number; subcategory?: string; center?: string; comment?: string }
  >(
    'clientRequestCreate',
    async ({enterpriseId, subcategory, center, comment}) =>
      await ClientRequestCreateQuery(enterpriseId, Session.getIndividualId(), subcategory, center, comment),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

    const clientRequestCategoriesUseMutation = useMutation<
        HelpdeskCategories,
        unknown,
        { enterpriseId: number; }
    >(
        'clientRequestCategories',
        async ({enterpriseId}) =>
            await ClientRequestCategoriesQuery(enterpriseId, Session.getIndividualId()),
        {
            onError: async (err: unknown) => throwAppException(Number(err))
        }
    )

  /* Members */
  const listMemberUseMutation = useMutation<IMember[], unknown, number>(
    'memberList',
    async (enterpriseId: number) => await MemberListQuery(enterpriseId),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  /* Payments */
  const paymentMethodListUseMutation = useMutation<IPaymentMethod[], unknown, string>(
    'paymentMethodList',
    async (token: string) => await PaymentMethodListQuery(token),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const paymentGetUseMutation = useMutation<IPayment, unknown, string>(
    'paymentGet',
    async (token: string) => await PaymentGetQuery(token),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const creditGetUseMutation = useMutation<ICredit, unknown, number>(
    'creditGet',
    async (creditId) => await CreditGetQuery(currentEnterprise, creditId),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const gcMandateLinkGetUseMutation = useMutation<IRedirectFlowGc, unknown, string>(
    'gcMandateLinkGet',
    async (token: string) => await GcMandateLinkGetQuery(token),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const createPaymentIntentUseMutation = useMutation<
    any,
    unknown,
    { token: string; paymentMethod: string; email: string }
  >(
    'createPaymentIntent',
    async ({token, paymentMethod, email}) =>
      await CreatePaymentIntentQuery(token, paymentMethod, email),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const completePaymentGcUseMutation = useMutation<
    any,
    unknown,
    { token: string; paymentIntent: IPaymentIntent }
  >(
    'completePaymentGc',
    async ({token, paymentIntent}) => await CompletePaymentCbQuery(token, paymentIntent),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  /* Quotations */
  const quotationGetUseMutation = useMutation<IQuotation, unknown, string>(
    'quotationGet',
    async (token: string) => await QuotationGetQuery(token),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  /* Others */

    const rulesDownloadUseMutation = useMutation<IFile, unknown, void>(
        'rulesOfProcedureDownload',
        async () => await RulesDownloadQuery(),
        {
            onError: async (err: unknown) => throwAppException(Number(err))
        }
    )

  const termsOfSalesDownloadUseMutation = useMutation<IFile, unknown, number | undefined>(
    'termsOfSalesDownload',
    async (termsOfSales?: number) => await TermsOfSalesDownloadQuery(termsOfSales),
    {
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const tokenCheckUseMutation = useMutation<void, unknown, string>(
    'tokenCheck',
    async (token: string) => await TokenCheckQuery(token),
  )

  const contextValue = useMemo(
    () => ({
      /* Accounting documents */
      listAccountingDocumentUseMutation,
      listAccountingDocumentIndividualUseMutation,

      /* Booking */
      listCustomerReservationUseMutation,
      listMeetingServiceUseMutation,
      listCommonMeetingServiceUseMutation,
      customerReservationCreateUseMutation,
      listScheduleDesktopUseMutation,
      pricingCustomerReservationUseMutation,
      deleteContractServiceUseMutation,
      nomadeCounterUseMutation,

      /* Contracts */
      requestSmsPendingContractUseMutation,
      sendSmsPendingContractUseMutation,
      contractDetailsUseMutation,
      contractPdfUseMutation,

      /* Documents */
      listDocumentUseMutation,
      documentDetailsUseMutation,
      documentDownloadUseMutation,

      /* Enterprises */
      paymentMethodListConnectedUseMutation,
      gcMandateConnectedLinkGetUseMutation,

      /* Helpdesk */
      listClientRequestUseMutation,
      clientRequestUseMutation,
      clientRequestCategoriesUseMutation,

      /* Members */
      listMemberUseMutation,

      /* Payments */
      paymentMethodListUseMutation,
      paymentGetUseMutation,
      gcMandateLinkGetUseMutation,
      createPaymentIntentUseMutation,

      /* Credits */
      creditGetUseMutation,

      /* Quotations */
      quotationGetUseMutation,

      /* Others */
      rulesDownloadUseMutation,
      termsOfSalesDownloadUseMutation,
      completePaymentGcUseMutation,
      tokenCheckUseMutation
    }),
    [
      /* Accounting documents */
      listAccountingDocumentUseMutation,
      listAccountingDocumentIndividualUseMutation,

      /* Booking */
      listCustomerReservationUseMutation,
      listMeetingServiceUseMutation,
      listCommonMeetingServiceUseMutation,
      customerReservationCreateUseMutation,
      listScheduleDesktopUseMutation,
      pricingCustomerReservationUseMutation,
      deleteContractServiceUseMutation,
      nomadeCounterUseMutation,

      /* Contracts */
      requestSmsPendingContractUseMutation,
      sendSmsPendingContractUseMutation,
      contractDetailsUseMutation,
      contractPdfUseMutation,

      /* Documents */
      listDocumentUseMutation,
      documentDetailsUseMutation,
      documentDownloadUseMutation,

      /* Enterprises */
      paymentMethodListConnectedUseMutation,
      gcMandateConnectedLinkGetUseMutation,

      /* Helpdesk */
      listClientRequestUseMutation,
      clientRequestUseMutation,
      clientRequestCategoriesUseMutation,

      /* Members */
      listMemberUseMutation,

      /* Payments */
      paymentMethodListUseMutation,
      paymentGetUseMutation,
      gcMandateLinkGetUseMutation,
      createPaymentIntentUseMutation,
      completePaymentGcUseMutation,

      /* Credits */
      creditGetUseMutation,

      /* Quotations */
      quotationGetUseMutation,

      /* Others */
      rulesDownloadUseMutation,
      termsOfSalesDownloadUseMutation,
      tokenCheckUseMutation
    ]
  )

  return <OnViewContext.Provider value={contextValue}>{children}</OnViewContext.Provider>
}

export default OnViewProvider

export const useOnView = (): OnViewContextType => useContext<OnViewContextType>(OnViewContext)
